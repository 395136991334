/* -------------------------------------------------------------------------- */
/*                                    Colors                                  */
/* -------------------------------------------------------------------------- */

$cr-black-1: #555454;
$cr-black-2: #1e1e1e;
$cr-black-3: #000000;

$cr-white-1: #ffffff;
$cr-white-2: #fcfcfc;
$cr-white-3: #f2f2f2;
$cr-white-4: #FFFDFC;
$cr-white-5: #FBFBFB;

$cr-brown-1: #bc8d63;

$cr-grey-1: #d9d9d9;
$cr-grey-2: #8e8383;
$cr-grey-3: #cccccc;
$cr-grey-4: #dfdfdf;
$cr-grey-5: #a5a5a5;

$cr-red-1: #ff0000;

// Exporting global colors to js
:export {
  crBlack1: $cr-black-1;
  crBlack2: $cr-black-2;
  crBlack3: $cr-black-3;

  crWhite1: $cr-white-1;
  crWhite2: $cr-white-2;
  crWhite3: $cr-white-3;
  crWhite4: $cr-white-4;

  crBrown1: $cr-brown-1;

  crGrey1: $cr-grey-1;
  crGrey2: $cr-grey-2;
  crGrey3: $cr-grey-3;
}
